import React from "react"
import PropTypes from "prop-types"
import { Button } from "grommet"
import SubscribeButton from "../SubscribeButton"
import Anchor from "../Anchor"

const CTAButton = ({ cta, ...props }) => {
  const isPlainButton = cta === "ENTER_PORTAL"
  const Component = isPlainButton ? Button : SubscribeButton
  return (
    <Component
      as={Anchor}
      {...props}
      href={isPlainButton ? props.endpoint : undefined}
    />
  )
}

CTAButton.propTypes = {}

export default CTAButton
