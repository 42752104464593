import * as React from "react"
import { graphql } from "gatsby"

import HomeHero from "../components/HomeHero"
import Dynamic from "../components/Dynamic"

const HomePage = ({ data: { strapiHomepage }, location }) => {
  const {
    dynamic,
    hero,
    localImage_slide,
    localImage_grid_picture,
    localImage_column,
    localImage_button_list,
    localImage_featured_articles,
  } = strapiHomepage

  const localImages = {
    "sections.carousel": localImage_slide,
    "sections.picture-grid": localImage_grid_picture,
    "sections.column-set": localImage_column,
    "sections.button-list": localImage_button_list,
    "sections.featured-articles": localImage_featured_articles,
  }

  return (
    <>
      <HomeHero {...hero} localImages={localImages} />
      <Dynamic sections={dynamic} localImages={localImages} />
    </>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    strapiHomepage(locale: { eq: "en" }) {
      id
      locale
      hero {
        content
        cta
        cta_label
        cta_endpoint
        id
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      localImage_featured_articles {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_button_list {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_column {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_slide {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_grid_picture {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      dynamic
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1500
                height: 1500
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
