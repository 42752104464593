import { Box, Heading } from "grommet"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Markdown from "./common/Markdown"
import { useSize } from "../hooks/useSize"
import CTAButton from "./common/CTAButton"

const HomeHero = ({
  content,
  cta,
  image: { localFile },
  title,
  cta_endpoint,
  cta_label,
}) => {
  const image = getImage(localFile)
  const size = useSize()
  const alignment = size === "small" ? "center" : "start"

  return (
    <Box
      height={{ min: "90vh" }}
      pad={{ vertical: "small" }}
      width={"xxlarge"}
      direction={size === "small" ? "column-reverse" : "row"}
      alignSelf="center"
      justify="center"
    >
      <Box
        gridArea="text"
        style={{ zIndex: 3 }}
        justify="center"
        align={alignment}
        pad={{ horizontal: "large" }}
      >
        <Box margin={{ vertical: "medium" }} gap="medium">
          <Heading
            level={1}
            size={["small", "medium"].includes(size) ? "medium" : "large"}
            textAlign={alignment}
            margin="none"
          >
            {title}
          </Heading>
          <Markdown textAlign={alignment}>{content}</Markdown>
        </Box>
        <CTAButton
          cta={cta}
          margin={"none"}
          align={alignment}
          endpoint={cta_endpoint}
          label={cta_label}
        />
      </Box>
      <Box
        width="large"
        justify="center"
        align="center"
        pad={{ horizontal: "large" }}
      >
        <GatsbyImage image={image} alt="" />
      </Box>
    </Box>
  )
}

export default HomeHero
