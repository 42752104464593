import Homepage from "../index"

import React from "react"
import { graphql } from "gatsby"

const LangHomepage = props => {
  return <Homepage {...props} />
}

LangHomepage.propTypes = {}

export default LangHomepage

export const pageQuery = graphql`
  query($locale: String!) {
    strapiHomepage(locale: { eq: $locale }) {
      id
      locale
      hero {
        content
        cta
        cta_label
        cta_endpoint
        id
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
                width: 500
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      localImage_featured_articles {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
            width: 300
            height: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_button_list {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_column {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_slide {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      localImage_grid_picture {
        id
        url
        name
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
            width: 300
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      dynamic
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                transformOptions: { fit: INSIDE, cropFocus: ATTENTION }
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`
